<template>
    <span>
        <el-col :span="span">
            <el-form-item label="机构类型">
                <el-select v-model="deptType" value="deptType">
                    <el-option
                        v-for="item of deptTypes"
                        :label="item.name"
                        :value="item.deptType"
                        :key="item.deptType"
                    />
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :span="span">
            <el-form-item :label="deptLabel || '机构名称'" prop="deptCode">
                <el-select v-model="deptCode" value="deptCode" filterable>
                    <el-option v-for="item of depts" :label="item.name" :value="item.deptCode" :key="item.deptCode" />
                </el-select>
            </el-form-item>
        </el-col>
    </span>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';

export default {
    name: 'EfDeptTypeAndDeptSelect',
    props: {
        needDefaultDeptType: {
            type: Boolean,
            default: false,
        },
        deptLabel: {
            type: String,
            default: '机构名称',
        },
        //机构名称是否需要添加全部选项 并且默认值不为全部（需要将needDefaultDeptType设置为true才可以使用）
        needSelectAllDept: {
            type: Boolean,
            default: false,
        },
        span: {
            type: Number,
            default: 8,
        },
    },
    data() {
        return {
            deptType: '',
            deptCode: '',
            //机构类型列表
            deptTypes: [],
            depts: [],
            //设置为null，需要根据是否为null进行后续逻辑判断
            deptTypeWithDeptsArr: null,
        };
    },
    watch: {
        deptType: {
            handler(newDeptType, oldDeptType) {
                this.$emit('selectDeptType', newDeptType);
                this.filterDeptsByDeptTypeAndSetDefaultDeptCode();
            },
            immediate: true,
        },
        deptCode: {
            handler(newDeptCode, oldDeptCode) {
                //触发父组件监听的事件
                let dept = {};
                dept = this.depts.find((item) => {
                    return item.deptCode === newDeptCode;
                });
                const deptName = dept ? dept.name : '';
                const deptType = dept ? dept.type : null;
                this.$emit('selectDept', newDeptCode, deptName, deptType);
            },
        },
    },
    beforeCreate() {
        UrlUtils.DeptTrees(this, async (rst) => {
            //对机构列表查询结果进行处理，组装成组件需要的数据机构
            const deptTypeWithDeptsArr = [];
            for (const o of rst || []) {
                this.deptTypes.push(new DeptType(o.type, o.name));
                const deptsOfDeptType = new DeptsOfDeptType(o.type, []);
                for (const e of o.children) {
                    deptsOfDeptType.depts.push(new Dept(e.code, e.name, e.type, e.deptGroupCode));
                }
                deptTypeWithDeptsArr.push(deptsOfDeptType);
            }
            this.deptType = this.deptTypes[0].deptType;
            //最后阶段再赋值
            if (this.needDefaultDeptType) {
                let lastIdx = this.deptTypes.length - 1;
                if (lastIdx < 0) {
                    lastIdx = 0;
                }
                //最后一项设置为默认值
                this.deptType = this.deptTypes[lastIdx].deptType;
            } else {
                //设置默认空值
                this.deptTypes.unshift(new DeptType('', '请选择'));
                deptTypeWithDeptsArr.unshift(new DeptsOfDeptType('', [new Dept('', '请选择', null)]));
            }
            this.deptTypeWithDeptsArr = deptTypeWithDeptsArr;
            await this.filterDeptsByDeptTypeAndSetDefaultDeptCode();
            //加载完成标识
            this.$emit('update:componentLoadingCompleteFlag', true);
        });
    },
    methods: {
        filterDeptsByDeptTypeAndSetDefaultDeptCode() {
            this.depts = [];
            for (const e of this.deptTypeWithDeptsArr || []) {
                if (Util.isEmpty(this.deptType)) {
                    this.depts.push(...e.depts);
                } else if (e.deptType === this.deptType) {
                    this.depts.push(...e.depts);
                }
            }
            if (this.depts.length >= 1) {
                if (this.needSelectAllDept && this.needDefaultDeptType) {
                    //有默认值 添加全部选项
                    this.depts.unshift(new Dept('', '全部', null));
                    //添加 有全部选项 默认值不设置为全部
                    this.deptCode = this.depts[1].deptCode;
                } else {
                    this.deptCode = this.depts[0].deptCode;
                }
            }
        },
        /**初始化设置机构的方法，通过$refs调用*/
        initNowPage(deptCode) {
            const timeSetInterval = setInterval(() => {
                if (this.depts.length > 0) {
                    this.deptCode = deptCode;
                    clearInterval(timeSetInterval);
                }
            }, 100);
        },
    },
};

class DeptType {
    constructor(deptType, name) {
        this.deptType = deptType;
        this.name = name;
    }
}

class Dept {
    constructor(deptCode, name, type, deptGroupCode) {
        this.deptCode = deptCode;
        this.name = name;
        this.type = type;
        this.deptGroupCode = deptGroupCode;
    }
}

class DeptsOfDeptType {
    constructor(deptType, depts) {
        this.deptType = deptType;
        this.depts = depts;
    }
}
</script>
