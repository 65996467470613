<!--支付类型-->
<!--<div class='PayType'></div>-->
<template>
    <div class="PayType" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: left" v-model="form" size="small">
                <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.finance.payType.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.finance.payType.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="170" prop="code" v-if="showColumn('code')" />
                <el-table-column label="机构名称" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="名称" width="160" prop="template.name" v-if="showColumn('template.name')" />
                <el-table-column label="标志" width="120" prop="template.flag" v-if="showColumn('template.flag')" />
                <el-table-column
                    label="可支付(收银)"
                    width="110"
                    prop="template.canPay"
                    v-if="showColumn('template.canPay')"
                    key="template.canPay"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.template.canPay">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可退款(收银)"
                    width="110"
                    prop="template.canRefund"
                    v-if="showColumn('template.canRefund')"
                    key="template.canRefund"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.template.canRefund">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可抹零(收银)"
                    width="110"
                    prop="template.canFloor"
                    v-if="showColumn('template.canFloor')"
                    key="template.canFloor"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.template.canFloor">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可组合支付(收银)"
                    width="130"
                    prop="template.canCombinationPay"
                    v-if="showColumn('template.canCombinationPay')"
                    key="template.canCombinationPay"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.template.canCombinationPay">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可充值(会员)"
                    width="130"
                    prop="template.canRecharge"
                    v-if="showColumn('template.canRecharge')"
                    key="template.canRecharge"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.template.canRecharge">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可退款(会员)"
                    width="110"
                    prop="template.canRechargeRefund"
                    v-if="showColumn('template.canRechargeRefund')"
                    key="template.canRechargeRefund"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.template.canRechargeRefund">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="启用" width="120" prop="enable" v-if="showColumn('enable')" key="enable">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.enable ? '是' : '' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="操作时间" width="160" prop="createTime" v-if="showColumn('createTime')" />

                <el-table-column
                    label="操作"
                    width="145"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.finance.payType.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.finance.payType.edit')"
                            >编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'PayType',
    components: { EfDeptTypeAndDeptSelect, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptCode: '',
                page: 1,
                limit: 20,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/finance/pay/payType/page',
            },
            current: {
                typeIdx: 3,
                deptCode: '',
            },
            meta: {
                types: [],
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },

        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '支付类型', '/finance/pay/payType/export', this.form, codes);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.finance.payType.detail', ['财务管理', '支付类型', '支付类型详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.finance.payType.edit', ['财务管理', '支付类型', '编辑支付类型'], {
                form: row,
                code: row.code,
            });
        },

        handleSelectType(typeIndex) {
            this.form.deptCode = this.meta.types[typeIndex].children[0].code;
            this.handleSelectDept(this.form.deptCode);
        },
        handleSelectDept(code) {
            if (!code) {
                return;
            }
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
    },
};
</script>

<style scoped>
.PayType .el-form-item {
    margin-bottom: 0;
}
</style>
